<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('dealer_panel.fertilizer_sales_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6">
            <ValidationProvider name="Division" vid="division_id">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro_division.division')"
                  label-for="division_id"
              >
                <b-form-select
                    plain
                    v-model="search.division_id"
                    :options="divisionList"
                    id="division_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6">
            <ValidationProvider name="District" vid="district_id">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('warehouse_report.district')"
                  label-for="district_id"
              >
                <b-form-select
                    plain
                    v-model="search.district_id"
                    :options="districtList"
                    id="district_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6">
            <ValidationProvider name="Upazila" vid="upazilla_id">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('warehouse_report.upazilla')"
                  label-for="upazilla_id"
              >
                <b-form-select
                    plain
                    v-model="search.upazilla_id"
                    :options="upazilaList"
                    id="upazilla_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6">
            <ValidationProvider name="Dealer Name" vid="dealer_id">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('fertilizerConfig.dealer_name')"
                  label-for="dealer_name"
              >
                <template v-slot:label>
                  {{$t('fertilizerConfig.dealer_name')}}
                </template>
                <b-form-select
                    plain
                    v-model="search.dealer_id"
                    :options="dealerBasicList"
                    id="dealer_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6">
            <ValidationProvider name="Dealer Code" vid="dealer_code" rules= "required">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="dealer_code"
              >
                <template v-slot:label>
                  {{$t('fertilizerReport.dealer_code')}}  <span class="text-danger">*</span>
                </template>
                <b-form-input
                    id="dealer_code"
                    v-model="search.dealer_code"
                    placeholder="*Dealer Code/Dealer Name is required"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{$t('warehouse_config.fiscal_year')}}
              </template>
              <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="fiscal_year_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="month"
            >
              <template v-slot:label>
                {{$t('pusti_mapping.month')}}
              </template>
              <b-form-select
                  plain
                  v-model="search.month"
                  :options="monthList"
                  id="month"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="allocation_type_id"
            >
              <template v-slot:label>
                {{$t('fertilizerSales.allocationType')}}
              </template>
              <b-form-select
                  plain
                  v-model="search.allocation_type_id"
                  :options="allocationTypeList"
                  id="allocation_type_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
        <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{  $t('dealer_panel.fertilizer_sales_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                              <b-button variant="light" @click="pdfExport" class="mr-2">
                              {{  $t('globalTrans.export_pdf') }}
                              </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                    {{ $t('dealer_panel.fertilizer_sales_report') }}
                                </list-report-head>
                              </b-col>
                            </b-row>
                            <div class="text-black">
                              <b-row>
                                <b-col md="4"><strong>{{ $t('org_pro_division.division') }}: </strong>{{ search.division_id ? (currentLocale === 'en' ? searchResult.division_name : searchResult.division_name_bn) : $t('globalTrans.all')  }}</b-col>
                                <b-col md="4"><strong>{{ $t('warehouse_report.district') }}: </strong>{{ search.district_id  ?  (currentLocale === 'en' ? searchResult.district_name : searchResult.district_name_bn) : $t('globalTrans.all') }}</b-col>
                                <b-col md="4"><strong>{{ $t('warehouse_report.upazilla') }}: </strong>{{ search.upazilla_id ?  (currentLocale === 'en' ? searchResult.upazilla_name : searchResult.upazilla_name_bn) : $t('globalTrans.all') }}</b-col>
                              </b-row>
                              <b-row class="my-4">
                                <b-col md="4"><strong>{{ $t('fertilizerConfig.dealer_name') }}: </strong>{{ search.dealer_id ? (currentLocale === 'en' ? searchResult.dealer_name : searchResult.dealer_name_bn) : $t('globalTrans.all')  }}</b-col>
                                <b-col md="4"><strong>{{ $t('fertilizerReport.dealer_code') }}: </strong>{{ search.dealer_code }}</b-col>
                              </b-row>
                              <b-row>
                                <b-col md="4"><strong>{{ $t('warehouse_config.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? (currentLocale === 'en' ? searchResult.fiscal_year : searchResult.fiscal_year_bn) : $t('globalTrans.all')  }}</b-col>
                                <b-col md="4"><strong>{{ $t('pusti_mapping.month') }}: </strong>{{ search.month  ?  (currentLocale === 'en' ? searchResult.month : searchResult.month_bn) : $t('globalTrans.all') }}</b-col>
                                <b-col md="4"><strong>{{ $t('fertilizerSales.allocationType') }}: </strong>{{ search.allocation_type_id ?  (currentLocale === 'en' ? searchResult.allocation_type : searchResult.allocation_type_bn) : $t('globalTrans.all') }}</b-col>
                              </b-row>
                            </div>
                            <b-row>
                              <b-col md="12" class="table-responsive">
                                <b-table-simple bordered hover small caption-top responsive>
                                  <b-thead>
                                    <b-tr>
                                      <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                      <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('fertilizerConfig.dealer_name') }}</b-th>
                                      <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('warehouse_config.fiscal_year') }}</b-th>
                                      <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('pusti_mapping.month') }}</b-th>
                                      <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('fertilizerSales.allocationType') }}</b-th>
                                      <b-th style="width:10%" class="text-center" :colspan="fertilizerNameList.length">{{ $t('dealer_panel.allocation_amount') }}</b-th>
                                      <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('dealer_panel.sales_date') }}</b-th>
                                      <b-th style="width:10%" class="text-center" :colspan="fertilizerNameList.length">{{ $t('dealer_panel.sales_amount') }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                      <b-th style="width:10%" class="text-right" v-for="(fertilizer, index) in fertilizerNameList" :key="index">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}</b-th>
                                      <b-th style="width:10%" class="text-right" v-for="(fertilizer, index) in fertilizerNameList" :key="index">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <template v-if="reportData.length">
                                      <template v-for="(item, index) in reportData">
                                        <template v-for="(dealerItem, dealerInfoIndex) in item.dealer_info">
                                          <template v-if="!dealerItem.sales_info.length">
                                            <b-tr :key="index+100+(dealerInfoIndex+100)" v-if="dealerInfoIndex === 0">
                                              <b-td style="text-align:center" :rowspan="item.dealer_info.length">
                                                {{ $n(index + 1) }}
                                              </b-td>
                                              <b-td style="text-align:center" :rowspan="item.dealer_info.length">
                                                {{ currentLocale === 'en' ? item.dealer_name : item.dealer_name_bn }}
                                              </b-td>
                                              <b-td style="text-align:center">
                                                {{ currentLocale === 'en' ? dealerItem.fiscal_year : dealerItem.fiscal_year_bn }}
                                              </b-td>
                                              <b-td style="text-align:center">
                                                {{ currentLocale === 'en' ? dealerItem.month_name : dealerItem.month_name_bn }}
                                              </b-td>
                                              <b-td style="text-align:center">
                                                {{ currentLocale === 'en' ? dealerItem.allocation_type : dealerItem.allocation_type_bn }}
                                              </b-td>
                                              <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                                {{ dealerItem.allocation_fertilizer_info[fertilizerName.value] ? $n(dealerItem.allocation_fertilizer_info[fertilizerName.value]) : '' }}
                                              </b-td>
                                              <td></td>
                                              <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right"></b-td>
                                            </b-tr>
                                            <b-tr :key="index+200+(dealerInfoIndex+200)" v-else>
                                              <b-td style="text-align:center">
                                                {{ currentLocale === 'en' ? dealerItem.fiscal_year : dealerItem.fiscal_year_bn }}
                                              </b-td>
                                              <b-td style="text-align:center">
                                                {{ currentLocale === 'en' ? dealerItem.month_name : dealerItem.month_name_bn }}
                                              </b-td>
                                              <b-td style="text-align:center">
                                                {{ currentLocale === 'en' ? dealerItem.allocation_type : dealerItem.allocation_type_bn }}
                                              </b-td>
                                              <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                                {{ dealerItem.allocation_fertilizer_info[fertilizerName.value] ? $n(dealerItem.allocation_fertilizer_info[fertilizerName.value]) : '' }}
                                              </b-td>
                                              <td></td>
                                              <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right"></b-td>
                                            </b-tr>
                                          </template>
                                          <template v-else>
                                            <template v-for="(salesItem, salesIndex) in dealerItem.sales_info">
                                              <b-tr :key="index+500+(dealerInfoIndex+500+salesIndex+500)" v-if="dealerInfoIndex === 0 && salesIndex === 0">
                                                <b-td style="text-align:center" :rowspan="item.total_sales_item">
                                                  {{ $n(index + 1) }}
                                                </b-td>
                                                <b-td style="text-align:center" :rowspan="item.total_sales_item">
                                                  {{ currentLocale === 'en' ? item.dealer_name : item.dealer_name_bn }}
                                                </b-td>
                                                <b-td style="text-align:center" :rowspan="dealerItem.sales_info.length">
                                                  {{ currentLocale === 'en' ? dealerItem.fiscal_year : dealerItem.fiscal_year_bn }}
                                                </b-td>
                                                <b-td style="text-align:center" :rowspan="dealerItem.sales_info.length">
                                                  {{ currentLocale === 'en' ? dealerItem.month_name : dealerItem.month_name_bn }}
                                                </b-td>
                                                <b-td style="text-align:center" :rowspan="dealerItem.sales_info.length">
                                                  {{ currentLocale === 'en' ? dealerItem.allocation_type : dealerItem.allocation_type_bn }}
                                                </b-td>
                                                <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right" :rowspan="dealerItem.sales_info.length">
                                                  {{ dealerItem.allocation_fertilizer_info[fertilizerName.value] ? $n(dealerItem.allocation_fertilizer_info[fertilizerName.value]) : '' }}
                                                </b-td>
                                                <td>{{ salesItem.sell_date | dateFormat }}</td>
                                                <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                                  {{ salesItem.fertilizer_info[fertilizerName.value] !== 'undefined' ? salesItem.fertilizer_info[fertilizerName.value] : '' }}
                                                </b-td>
                                              </b-tr>
                                              <b-tr :key="index+600+(dealerInfoIndex+600+salesIndex+600)" v-else-if="dealerInfoIndex > 0 && salesIndex === 0">
                                                <b-td style="text-align:center" :rowspan="dealerItem.sales_info.length">
                                                  {{ currentLocale === 'en' ? dealerItem.fiscal_year : dealerItem.fiscal_year_bn }}
                                                </b-td>
                                                <b-td style="text-align:center" :rowspan="dealerItem.sales_info.length">
                                                  {{ currentLocale === 'en' ? dealerItem.month_name : dealerItem.month_name_bn }}
                                                </b-td>
                                                <b-td style="text-align:center" :rowspan="dealerItem.sales_info.length">
                                                  {{ currentLocale === 'en' ? dealerItem.allocation_type : dealerItem.allocation_type_bn }}
                                                </b-td>
                                                <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right" :rowspan="dealerItem.sales_info.length">
                                                  {{ dealerItem.allocation_fertilizer_info[fertilizerName.value] ? $n(dealerItem.allocation_fertilizer_info[fertilizerName.value]) : '' }}
                                                </b-td>
                                                <td>{{ salesItem.sell_date | dateFormat }}</td>
                                                <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                                  {{ salesItem.fertilizer_info[fertilizerName.value] !== 'undefined' ? salesItem.fertilizer_info[fertilizerName.value] : '' }}
                                                </b-td>
                                              </b-tr>
                                              <b-tr :key="index+700+(dealerInfoIndex+700+salesIndex+700)" v-else>
                                                <td>{{ salesItem.sell_date | dateFormat }}</td>
                                                <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-right">
                                                  {{ salesItem.fertilizer_info[fertilizerName.value] !== 'undefined' ? salesItem.fertilizer_info[fertilizerName.value] : '' }}
                                                </b-td>
                                              </b-tr>
                                            </template>
                                          </template>
                                        </template>
                                      </template>
                                    </template>
                                    <template v-else>
                                      <b-tr>
                                        <b-td :colspan="totalTableColumns" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                      </b-tr>
                                    </template>
                                  </b-tbody>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { fertilizerSalesReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export_pdf_details'
import flatpickr from 'flatpickr'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'Dealer Name': 'fertilizerName',
  'Fiscal Year': 'requisitionAmount',
  Month: 'stockBalance',
  'Allocation Type': 'stockBalance',
  'Requisition Date': 'stockBalance'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'ডিলারের নাম': 'fertilizerName',
  অর্থবছর: 'requisitionAmount',
  মাস: 'stockBalance',
  'বরাদ্দের ধরন': 'stockBalance',
  'চাহিদাপত্র জমার তারিখ': 'stockBalance'
}

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 3,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        dealer_code: '',
        dealer_id: 0,
        fertilizer_type_id: 0,
        fertilizer_id: 0,
        requisition_date: null,
        fiscal_year_id: 0,
        month: 0,
        allocation_type_id: 0,
        from_date: '',
        to_date: ''
      },
      searchResult: {
        division_name: '',
        division_name_bn: '',
        district_name: '',
        district_name_bn: '',
        upazilla_name: '',
        upazilla_name_bn: '',
        dealer_name: '',
        dealer_name_bn: '',
        fiscal_year: '',
        fiscal_year_bn: '',
        month_name: '',
        month_name_bn: '',
        allocation_type: '',
        allocation_type_bn: ''
      },
      reportData: [],
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      loading: false,
      districtList: [],
      upazilaList: [],
      dealerBasicList: []
    }
  },
  computed: {
      json_fields: function () {
        return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
      },
      dataCustomizeExcel () {
        var serial = 0
        const customizeData = this.reportData.map(item => {
          serial += 1
          if (this.currentLocale === 'bn') {
            item.serial = this.$n(serial)
            item.fertilizerName = item.fertilizer_name_bn
            item.requisitionAmount = this.$n(item.Requisition_Amount)
            item.stockBalance = this.$n(item.Stock_Balance)
          } else {
            item.serial = this.$n(serial)
            item.fertilizerName = item.fertilizer_name
            item.requisitionAmount = this.$n(item.Requisition_Amount)
            item.stockBalance = this.$n(item.Stock_Balance)
          }
          return Object.assign({}, item)
        })
        return customizeData
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('fertilizerConfig.fertilizer_name'), class: 'text-left' },
          { label: this.$t('dealer_panel.requisition_amount'), class: 'text-left' },
          { label: this.$t('dealer_panel.stock_balance'), class: 'text-left' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fertilizer_name_bn' },
          { key: 'Requisition_Amount' },
          { key: 'Stock_Balance' }
        ]
      } else {
        keys = [
           { key: 'index' },
          { key: 'fertilizer_name' },
          { key: 'Requisition_Amount' },
          { key: 'Stock_Balance' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    fertilizerTypeList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerTypeList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      const fiscalyear = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyear.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    allocationTypeList: function () {
      const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
      const tempData = type.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    currentLocale: function () {
        return this.$i18n.locale
    },
    totalTableColumns: function () {
      return 6 + (this.fertilizerNameList.length * 2)
    }
  },
   watch: {
     'search.division_id': function (newVal) {
       this.districtList = this.getDistrictList(newVal)
     },
     'search.district_id': function (newVal) {
       this.upazilaList = this.getUpazilaList(newVal)
     },
     'search.upazilla_id': function (newVal) {
       this.dealerBasicList = this.getDealerList(newVal)
     }
  },
  created () {
    // this.loadData()
  },
  mounted () {
    flatpickr('.fromDate', {})
    core.index()
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    getFertilizerList (fertilizerTypeId) {
      const fertilizerNameList = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
      if (fertilizerTypeId) {
        return fertilizerNameList.filter(fertilizerName => fertilizerName.fertilizer_type_id === fertilizerTypeId)
      }
      return fertilizerNameList
    },
    getDistrictList (divisionId = null) {
      const regionDistrictLists = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return regionDistrictLists.filter(district => district.division_id === divisionId)
      }
      return regionDistrictLists
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getDealerList (upazillaId = null) {
      const dealerList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1)
      if (upazillaId) {
        return dealerList.filter(dealer => dealer.per_upazilla_id === upazillaId)
      }
    },
    loadData () {
      this.loading = true
      RestApi.getData(seedFertilizerServiceBaseUrl, fertilizerSalesReport, this.search).then(response => {
        if (response.success) {
          if (this.search.division_id) {
            const divisionObj = this.divisionList.find(division => division.value === this.search.division_id)
            this.searchResult.division_name = divisionObj.text_en
            this.searchResult.division_name_bn = divisionObj.text_bn
          } else {
            this.searchResult.division_name = ''
            this.searchResult.division_name_bn = ''
          }
          if (this.search.district_id) {
            const districtObj = this.districtList.find(district => district.value === this.search.district_id)
            this.searchResult.district_name = districtObj.text_en
            this.searchResult.district_name_bn = districtObj.text_bn
          } else {
            this.searchResult.district_name = ''
            this.searchResult.district_name_bn = ''
          }
          if (this.search.upazilla_id) {
            const upazillaObj = this.upazilaList.find(upazilla => upazilla.value === this.search.upazilla_id)
            this.searchResult.upazilla_name = upazillaObj.text_en
            this.searchResult.upazilla_name_bn = upazillaObj.text_bn
          } else {
            this.searchResult.upazilla_name = ''
            this.searchResult.upazilla_name_bn = ''
          }
          if (this.search.dealer_id) {
            const dealerObj = this.dealerBasicList.find(dealer => dealer.value === this.search.dealer_id)
            this.searchResult.dealer_name = dealerObj.text_en
            this.searchResult.dealer_name_bn = dealerObj.text_bn
          } else {
            this.searchResult.dealer_name = ''
            this.searchResult.dealer_name_bn = ''
          }
          if (this.search.fiscal_year_id) {
            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === this.search.fiscal_year_id)
            this.searchResult.fiscal_year = fiscalYearObj.text_en
            this.searchResult.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.searchResult.fiscal_year = ''
            this.searchResult.fiscal_year_bn = ''
          }
          if (this.search.month) {
            const monthObj = this.monthList.find(month => month.value === parseInt(this.search.month))
            this.searchResult.month = monthObj.text_en
            this.searchResult.month_bn = monthObj.text_bn
          } else {
            this.searchResult.month = ''
            this.searchResult.month_bn = ''
          }
          if (this.search.allocation_type_id) {
            const allocationTypeObj = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.find(allocationType => allocationType.value === parseInt(this.search.allocation_type_id))
            this.searchResult.allocation_type = allocationTypeObj.text_en
            this.searchResult.allocation_type_bn = allocationTypeObj.text_bn
          } else {
            this.searchResult.allocation_type = ''
            this.searchResult.allocation_type_bn = ''
          }
          this.reportData = this.getCustomizedDataList(response.data)
        }
        this.loading = false
      }).catch(error => {
        if (error) {
          //
        }
        this.reportData = []
      })
    },
    getCustomizedDataList (data) {
      const listData = data.map(listItem => {
        const customizedObj = {}
        const dealerObj = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.find(dealer => dealer.value === listItem.dealer_id)
        const dealerInfo = listItem.dealer_info.map(item => {
          const customizedObj = {}
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
          const monthObj = this.monthList.find(month => month.value === parseInt(item.month))
          const allocationTypeObj = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.find(allocationType => allocationType.value === item.allocation_type_id)
          if (typeof fiscalYearObj !== 'undefined') {
            customizedObj.fiscal_year = fiscalYearObj.text_en
            customizedObj.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            customizedObj.fiscal_year = ''
            customizedObj.fiscal_year_bn = ''
          }
          if (typeof monthObj !== 'undefined') {
            customizedObj.month_name = monthObj.text_en
            customizedObj.month_name_bn = monthObj.text_bn
          } else {
            customizedObj.month_name = ''
            customizedObj.month_name_bn = ''
          }
          if (typeof allocationTypeObj !== 'undefined') {
            customizedObj.allocation_type = allocationTypeObj.text_en
            customizedObj.allocation_type_bn = allocationTypeObj.text_bn
          } else {
            customizedObj.allocation_type = ''
            customizedObj.allocation_type_bn = ''
          }
          return Object.assign({}, item, customizedObj)
        })
        if (typeof dealerObj !== 'undefined') {
          customizedObj.dealer_name = dealerObj.text_en
          customizedObj.dealer_name_bn = dealerObj.text_bn
        } else {
          customizedObj.dealer_name = ''
          customizedObj.dealer_name_bn = ''
        }
        customizedObj.dealer_info = dealerInfo
        return Object.assign({}, listItem, customizedObj)
      })
      return listData
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Fertilizer Sales Report' : 'সার বিক্রয় রিপোর্ট'
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
